import React from "react";
import "./Footer.scss";
import Copyright from "../Copyright/Copyright";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import Pay_Icon from "../PaymentIcon/Pay_Icon";
import { current } from "@reduxjs/toolkit";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

 
  return (
    <div className="footer_wrap ">
      <div className="footer">
        <div className="footer_my_container">
          <div className="footer_section_one">
            <h1>
              Follow us <br />{" "}
              <span style={{ color: "orange" }}>@xlsnacks</span>
            </h1>
            <div className="social_icon">
              {/* <a href="https://www.facebook.com/chocodateglobal/">
                <FacebookIcon />
              </a> */}
              <a href="https://www.instagram.com/xlchips/">
                <InstagramIcon />
              </a>
            </div>
          </div>

          <div className="footer_list">
            <div>
              <ul>
                <li>
                  <Link to="/about" onClick={scrollToTop}>
                    <p>Company</p>{" "}
                  </Link>
                </li>

                <li>
                  <Link to="/terms-&-conditions" onClick={scrollToTop}>
                    {" "}
                    <p>T&C</p>{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" onClick={scrollToTop}>
                    {" "}
                    <p>Privacy Policy</p>{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer_section_two">
            <div className="logo">
              <img src={require("../../img/white_logo.png")} />
            </div>

            <p>
              Notions Group is a dynamic group of companies innovating,
              manufacturing marketing and distributing brands that our consumers
              have grown to love. Headquartered in the ever-changing city of
              Dubai, we focus on building confectionary, date and snack brands
              that reflect both heritage and modernity.
            </p>
          </div>
        </div>
      </div>
      <Pay_Icon />
    </div>
  );
};

export default Footer;
